.container {
  display: flex;
  flex-direction: column;
  height: 95%;
  justify-content: space-between;
}

.container h2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 2opx;
}

.container img {
  width: 165px;
  height: 105px;
 object-fit: contain;
}

.content img {
  width: 24px;
  height: 24px;
  padding: 0px;
  margin-right: 5px;
}

.content {
  padding-left: 30px;
  cursor: pointer;
}

.admin-name {
  font-weight: 700;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 20px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  
}

.sidebar-list {
  display: flex;
  margin-bottom: 10px;
}

.link {
  text-decoration: none;
  color: inherit;
}

.active{
  transition: all 0.4s ease-in-out;
  background-color: #F2F2F2;
  border-radius: 10px;
  position: relative;
  /* borderLeft: '3px solid black',
  marginBottom: '10px', */
  /* border-left: 3px solid black; */
}
.active.submenu{
  transition: all 0.4s ease-in-out;
  border-radius: 10px;
  background-color: black;
  padding: 0 !important;
}
.active.submenu::before{
  left: 0;
  top: 10px;
  bottom: 0;
  width: 2px;
  height: 17px;
  transition: all 0.4s ease-in-out;
  border-radius: 10px;
  background-color: black;
  padding: 0 !important;
}
.active::before{
  content: '';
  position: absolute;
  left: 0;
  top: 10px;
  bottom: 0;
  width: 7px;
  height: 25px;
  background-color: black;
  border-radius: 10px;
}
.submenu{
  padding:0 !important;
}