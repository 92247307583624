.binary-survey-results {
  width: 100%;
  /* padding: 1em 1.5em 2em 1.5em; */
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  font-family: Inter;
  color: #2E3645;

  flex-grow: 1;
  /* Allows the component to grow and shrink as needed */
  overflow: hidden;
  /* Prevents overflow */
  /* display: flex; */
  /* align-items: center; */
  /* Centers content vertically */
  /* justify-content: center; */
  /* Centers content horizontally */
  padding: 1rem;
}

.header-icons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.question-title {
  color: #2E3645;
  font-size: 1em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2em;
}

.total-text {
  font-size: 0.9em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.total-responses {
  font-size: 0.88em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2em;
}

.total-responses-number {
  color: #2E3645;
  text-align: center;
  font-size: 1.3em;
  font-style: normal;
  font-weight: 600;
}

.response-bar {
  display: flex;
  height: 0.75em;
  margin-top: 0.95em;
  margin-bottom: 0.95em;
  border-radius: 1em;
  overflow: hidden;
}

.binary-option-1-bar {
  background-color: #2FA9E3;
}

.binary-option-0-bar {
  background-color: #D82C2C;
}

.response-counts {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 1.3em; */
}

.response-1-label,
.response-0-label {
  display: flex;
  align-items: center;
}

.binary-1-icon,
.binary-0-icon {
  margin-right: 0.3em;
}

.response-0-text,
.response-1-text {
  font-size: 0.87em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2em;
}

.response-0-box-highlight {
  border-radius: 4px;
  background-color: #FFEBEB;
}

.response-1-box-highlight {
  border-radius: 4px;
  background-color: #EEF1FF;
}

.response-0-box-highlight,
.response-1-box-highlight {
  margin-left: 0.6em;
  display: flex;
  padding: 0.4em 0.7em;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 0.75em;
  font-weight: 600;
}

.response-0-label-number {
  color: #D43333;
  text-align: center;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.response-1-label-number {
  color: #197DAD;
  text-align: center;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}