.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 16px;
    border-radius: 8px;
}

.table-title-row {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 16px;
    gap: 8px;
}

.svg-icon {
    display: inline-block;
}

.table-title-text {
    margin-left: 8px;
    margin-right: 8px;
    color: #2E3645;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600 !important;
}

.MuiTableCell-head {
    font-weight: 600 !important;
    font-size: 14px !important;
    border-bottom: 1px solid #E9EDF0;
}

.MuiTableCell-root {
    padding: 16px;
    color:#2E3645 !important; 
}

.details-button {
    border-color: #D9E1E7 !important;
    padding: 6px 12px !important;
    align-self: stretch !important;
    border-radius: 6px !important;
    color: #2FA9E3 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 17px !important;
    text-transform: none !important;
}
