.distribution-survey-results {
  width: 100%;
  padding: 1em 1em 1em 1em;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  font-family: Inter;
  color: #2E3645;
}

.distribution-top-icons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.distribution-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  width: 100%;
  gap: 0.5em;
}

.distribution-question-text {
  color: #2E3645;
  font-size: 1em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1em;
}

.distribution-chart-container {
  margin-bottom: 0.5em;
  border: 1px solid #E9EDF0;
  border-radius: 8px;
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.distribution-progress-bar-column {
  flex-grow: 1;
  padding-left: 1.2em;
  padding-right: 1em;
}

.distribution-answer-text {
  color: #828282;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 100%;
}

.distribution-label {
  display: flex;
  flex-direction: column;
  align-items: end;

  text-align: right;
  font-weight: 600;
  font-size: 10px;
  white-space: nowrap;
}

/* ProgressBar Container */
.progress-bar-container {
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 8px;
  height: 8px;
  overflow: hidden;
  margin-top: 1em;
}

/* ProgressBar Filled */
.progress-bar-filled {
  height: 100%;
  background-color: #4a90e2;
  /* Default color for progress bar */
  border-radius: 8px;
  transition: width 0.3s ease;
  /* Smooth width transition */
}