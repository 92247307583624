.survey-results {
  width: 100%;
  padding: 1em 1.5em 1em 1.5em;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  font-family: Inter;
  color: #2E3645;
}

.table-title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  /* margin-bottom: 16px; */
  /* gap: 8px; */
}

.table-title-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  gap: 0.4em;
}

.table-title-text {
  font-size: 1.25em !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 1.4em !important;
  letter-spacing: -0.01em !important;
}

.component-description {
  color: #606060;
  font-family: Inter;
  font-size: 0.88em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.optional-answer-value {
  margin-top: 0.3em;
  color: #2E3645;
  font-family: Inter;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.chart-title-label {
  margin-top: 1em;
  margin-bottom: 0em;
  font-size: 1em !important;
  font-weight: 600 !important;
}

.custom-legend {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 100%;
}

.price-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.price-display__label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: #2E3645;
  font-family: Inter;
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2em;
}

.price-display__dot {
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.price-display__value {
  color: #2E3645;
  font-family: Inter;
  font-size: 1.52em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.6em;
}

.custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 0.4em;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}