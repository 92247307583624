/* .chart-stack-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  gap: 16px;
} */

/* .stacked-chart {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0;
} */

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 0;
  padding: 0;
}

.chart-container>* {
  width: 100%;
  height: 100%;
}

.chart-container>*:not(:last-child) {
  margin-bottom: 0;
}



.chart-stack-container {  
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  align-items: center;
}

/* .chart-container {  
  display: flex;
  justify-content: center;
  align-items: center;
} */