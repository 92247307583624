.mc5-survey-results {
  width: 100%;
  padding: 1em 1.5em 1em 1.5em;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  font-family: Inter;
  color: #2E3645;
}

.mc5-table-title-row {
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 100%;
  margin-bottom: 0.5em;
}

.mc5-table-title-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.4em;
}

.mc5-table-title-text {
  font-size: 1.25em !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 1.4em !important;
  letter-spacing: -0.01em !important;
}

.mc5-component-description {
  color: #606060;
  font-family: Inter;
  font-size: 0.88em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mc5-chart-title-label {
  margin-top: 2.5em;
  margin-bottom: 0em;
  font-size: 10px !important;
  font-weight: 700 !important;

  color: #2E2E2E;

  display: inline-flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 2px solid #2E3645;
}

.mc5-custom-legend {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 90%;

  color: #2E3645;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}